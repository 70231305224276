require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
require("bootstrap")
require("./bootstrap-datepicker.min.js")
require("stylesheets/application")
require("@fortawesome/fontawesome-free/js/all")
require('shufflejs')

class Grid {
  constructor(element) {
    this.element = element;
    this.shuffle = new Shuffle(element, {
      itemSelector: '.plant-item',
    });

    this.addShuffleEventListeners();
    this.addSearchFilter();
  }

  addShuffleEventListeners() {
    this.shuffle.on(Shuffle.EventType.LAYOUT, (data) => {});
    this.shuffle.on(Shuffle.EventType.REMOVED, (data) => {});
  }

  _removeActiveClassFromChildren(parent) {
    const { children } = parent;
    for (let i = children.length - 1; i >= 0; i--) {
      children[i].classList.remove('active');
    }
  }

  addSearchFilter() {
    const searchInput = document.querySelector('.search');
    if (!searchInput) {
      return;
    }
    searchInput.addEventListener('keyup', this._parseSearchKeyup.bind(this));
  }

  _parseSearchKeyup(evt) {
    const searchText = evt.target.value.toLowerCase();
    this._handleSearchKeyup(searchText);
  }

  _handleSearchKeyup(searchText) {
    const st = searchText.toLowerCase();
    this.shuffle.filter((element, shuffle) => {
      // If there is a current filter applied, ignore elements that don't match it.
      if (shuffle.group !== Shuffle.ALL_ITEMS) {
        // Get the item's groups.
        const groups = JSON.parse(element.getAttribute('data-groups'));
        const isElementInCurrentGroup = groups.indexOf(shuffle.group) !== -1;
        // Only search elements in the current group
        if (!isElementInCurrentGroup) {
          return false;
        }
      }
      const titleElement = element.querySelector('.card-id');
      const titleText = titleElement.textContent.toLowerCase().trim();
      return titleText.indexOf(st) !== -1;
    });
  }
}

window.toggleCards = function() {
  $('#card_minimizer').toggleClass('fa-plus fa-minus')
  $('.minimizable').toggleClass('hidden');
  new Grid(document.getElementById('grid'));
}

window.toggleSearch = function(val) {
  $('#search-input').val(val);
  grid._handleSearchKeyup(val);
}

document.addEventListener('turbolinks:load', () => {
  if ( $( "#grid" ).length ) {
    $("#grid").removeClass('hidden');
    window.grid = new Grid(document.getElementById('grid'));
  }

  if ( $( "#grid2" ).length ) {
    window.grid = new Grid(document.getElementById('grid2'));
  }

  if ( $( "#grid3" ).length ) {
    window.grid = new Grid(document.getElementById('grid3'));
  }

  if ( $( ".datepicker" ).length ) {
    $( ".datepicker" ).datepicker({
      format: "MM dd, yyyy",
      todayHighlight: true,
      todayBtn: "linked",
      forceParse: false,
      autoclose: true,

    })
  }
});
